@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "HANDELGOTHICITCPRO-REGULAR";
  src: url("./fonts/HANDELGOTHICITCPRO-REGULAR.OTF") format("opentype");
}
@font-face {
  font-family: "HANDELGOTHICITCPRO-MEDIUM";
  src: url("./fonts/HANDELGOTHICITCPRO-MEDIUM.OTF") format("opentype");
}
@font-face {
  font-family: "MAGISTRAL-BOOK";
  src: url("./fonts/MAGISTRAL-BOOK.OTF") format("opentype");
}
@font-face {
  font-family: "MAGISTRAL-BOLD";
  src: url("./fonts/MAGISTRAL-BOLD.OTF") format("opentype");
}
@font-face {
  font-family: "CHALET-LONDONNINETEENSIXTY";
  src: url("./fonts/CHALET-LONDONNINETEENSIXTY.OTF") format("opentype");
}
@font-face {
  font-family: "CHALETE-LONDONSIXTY";
  src: url("./fonts/CHALETE-LONDONSIXTY.OTF") format("opentype");
}
@font-face {
  font-family: "CHALETE-LONDONEIGHTY";
  src: url("./fonts/CHALETE-LONDONEIGHTY.OTF") format("opentype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
.pixelated {
  image-rendering: pixelated;
}
.mini-scroll::-webkit-scrollbar {
  width: 2px;
}
/* Track */
.mini-scroll-dark::-webkit-scrollbar-track {
  background: #464646;
}
.mini-scroll::-webkit-scrollbar-track {
  background: #c6c6c6;
}
/* Handle */
.mini-scroll-dark::-webkit-scrollbar-thumb {
  background: #ffbf00;
}
.mini-scroll::-webkit-scrollbar-thumb {
  background: #545454;
}
/* Handle on hover */
.mini-scroll-dark::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.mini-scroll::-webkit-scrollbar-thumb:hover {
  background: #000000;
}
